<template>
  <div>
    <row class="row">
      <v-col class="d-flex justify-start" cols="2">
        <h1>VisitaImoveis360º</h1>
      </v-col>
      <v-col class="align-center black--text mt-4">
        <router-link to="/">
          <v-btn class="menu" plain> Home </v-btn>
        </router-link>
        <router-link to="/cidades">
          <v-btn class="menu" plain> Cidades e Bairros </v-btn>
        </router-link>
        <router-link to="/clientes" v-if="login.id_usuario == '0'">
          <v-btn class="menu" plain> Clientes </v-btn>
        </router-link>
        <router-link to="/usuarios">
          <v-btn class="menu" plain> Usuários </v-btn>
        </router-link>
        <router-link to="/imoveis">
          <v-btn class="menu" plain> Imóveis </v-btn>
        </router-link>
      </v-col>
      <v-col cols="2" class="d-flex justify-end">
        <v-menu bottom min-width="200px">
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on" class="mt-3">
                <h2 class="white--text" v-if="login.id_usuario == 0">Admin</h2>
                <v-img v-else 
                  :src="login.imagemLogado" 
                  width="150"
                  heidth="150"
                />
            </v-btn>
          </template>
          <v-card class="mt-10">
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <h5>{{ login.nome }}</h5>
                <p class="text-caption mt-1 mr-1 ml-1">
                  {{ login.nome_cliente }}
                </p>
                <v-divider class="my-3"></v-divider>
                <v-list dense>
                  <v-list-item @click="handleLogoff">
                    <svg-icon height="30px" width="30px" type="mdi" :path="icon_sair"></svg-icon>
                    <v-text class="links">Sair</v-text>
                  </v-list-item>
                </v-list>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </v-col>
    </row>
    <!-- <v-divider class="mt-16"></v-divider> -->
</div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiLogout } from '@mdi/js';
import { decriptar } from "../config/cripto";

export default {
  components: {
		SvgIcon
	},
  data() {
    return {
      icon_sair: mdiLogout,

      login: {
        id_usuario: "",
        id_cliente: "",
        nome_cliente: "",
        nome: "",
        token: "",
        imagemLogado: ""
      },
    };
  },

  methods: {
    handleLogoff(event) {
      event.preventDefault();

      localStorage.clear();
      window.location.href = "https://www.visitaimoveis360.com.br";
      // this.$router.push("/login");
    }
  },

  mounted() {
    if (localStorage.getItem("tk") == null) {
      this.$router.push("/login");
    }
    this.login.id_usuario = decriptar(localStorage.getItem("udi"));
    this.login.id_cliente = decriptar(localStorage.getItem('cdi'));
    this.login.nome_cliente = decriptar(localStorage.getItem('cno'));
    this.login.nome = decriptar(localStorage.getItem("no"));
    this.login.token = decriptar(localStorage.getItem("tk"));
    if ((localStorage.getItem("lg") == null) || (decriptar(localStorage.getItem("lg")).trim() == "" )) {
      this.login.imagemLogado = require("@/assets/logopadrao.png");
    } else {
      this.login.imagemLogado = process.env.VUE_APP_URL_FILES + "/l/" + decriptar(localStorage.getItem("lg"));
    }
  },
};
</script>

<style scoped>
.links {
  margin-left: 10px;
}
.row {
  width: 100%;
  height: 90px;
}
.menu {
  margin-top: -8px;
  color: white;
  /* font-weight: bold;
  font-family: Arial, Helvetica, sans-serif; */
}
</style>

