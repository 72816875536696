import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('row',{staticClass:"row"},[_c(VCol,{staticClass:"d-flex justify-start",attrs:{"cols":"2"}},[_c('h1',[_vm._v("VisitaImoveis360º")])]),_c(VCol,{staticClass:"align-center black--text mt-4"},[_c('router-link',{attrs:{"to":"/"}},[_c(VBtn,{staticClass:"menu",attrs:{"plain":""}},[_vm._v(" Home ")])],1),_c('router-link',{attrs:{"to":"/cidades"}},[_c(VBtn,{staticClass:"menu",attrs:{"plain":""}},[_vm._v(" Cidades e Bairros ")])],1),(_vm.login.id_usuario == '0')?_c('router-link',{attrs:{"to":"/clientes"}},[_c(VBtn,{staticClass:"menu",attrs:{"plain":""}},[_vm._v(" Clientes ")])],1):_vm._e(),_c('router-link',{attrs:{"to":"/usuarios"}},[_c(VBtn,{staticClass:"menu",attrs:{"plain":""}},[_vm._v(" Usuários ")])],1),_c('router-link',{attrs:{"to":"/imoveis"}},[_c(VBtn,{staticClass:"menu",attrs:{"plain":""}},[_vm._v(" Imóveis ")])],1)],1),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"2"}},[_c(VMenu,{attrs:{"bottom":"","min-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"mt-3",attrs:{"text":""}},on),[(_vm.login.id_usuario == 0)?_c('h2',{staticClass:"white--text"},[_vm._v("Admin")]):_c(VImg,{attrs:{"src":_vm.login.imagemLogado,"width":"150","heidth":"150"}})],1)]}}])},[_c(VCard,{staticClass:"mt-10"},[_c(VListItemContent,{staticClass:"justify-center"},[_c('div',{staticClass:"mx-auto text-center"},[_c('h5',[_vm._v(_vm._s(_vm.login.nome))]),_c('p',{staticClass:"text-caption mt-1 mr-1 ml-1"},[_vm._v(" "+_vm._s(_vm.login.nome_cliente)+" ")]),_c(VDivider,{staticClass:"my-3"}),_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":_vm.handleLogoff}},[_c('svg-icon',{attrs:{"height":"30px","width":"30px","type":"mdi","path":_vm.icon_sair}}),_c('v-text',{staticClass:"links"},[_vm._v("Sair")])],1)],1)],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }